'use client';

import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import Utils from '@/utils';

const UTM = () => {
  const searchParams = useSearchParams();

  useEffect(() => {
    if (searchParams) Utils.utmManager(searchParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default UTM;
