import type { InitOptions } from 'i18next';

export const fallbackLng = 'en';
export const locales = [fallbackLng, 'es', 'fr', 'de', 'cn'] as const;
export type LocaleTypes = (typeof locales)[number];
export const defaultNS = 'common';

export function getOptions(lang = fallbackLng): InitOptions {
  return {
    // debug: process.env.NODE_ENV === 'development' ? true : false, // Set to true to see console logs
    debug: false, // Set to true to see console logs
    supportedLngs: locales,
    fallbackLng,
    lng: lang,
    // fallbackNS: defaultNS,
    // defaultNS,
    react: { useSuspense: true },
    nonExplicitSupportedLngs: true,
    keySeparator: '/',
    interpolation: { escapeValue: false },
  };
}
