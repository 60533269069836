'use client';

import { useTranslation } from '@/i18n/client';
import Link from 'next/link';

const LKOffers = ({ lang }) => {
  const { t } = useTranslation(lang);

  return (
    <Link href={`/home/${lang}/plans`} className="lk-discount">
      <p>{t('LKOffers/key2')}</p>
      <span className="d-md-inline-flex d-none">{t('LKOffers/key1')}</span>
    </Link>
  );
};

export default LKOffers;
