'use client';

import { useEffect, useState } from 'react';
import { Col, Container, Nav, Navbar, Offcanvas, Row } from 'react-bootstrap';
import { BsList, BsX } from 'react-icons/bs';
import useScrollListener from '../hooks/useScrollListener';
import { useWindowSize } from '../hooks/useWindowSize';
import I18nLocaleChanger from './common/I18nLocaleChanger';
import { useTranslation } from '@/i18n/client';
import { useRouter, useSearchParams } from 'next/navigation';
import Link from 'next/link';
import Image from 'next/image';

const LKNavbar = ({ lang }) => {
  const { t, i18n } = useTranslation(lang);
  const [width] = useWindowSize();

  let searchParams = useSearchParams();
  const router = useRouter();

  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [showTabletMenu, setShowTabletMenu] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [shouldNavBeHidden, setShouldNavBeHidden] = useState(false);
  const scroll = useScrollListener();

  useEffect(() => {
    if (searchParams && searchParams.has('lum_last_user_loggedin')) {
      localStorage.setItem('_lum_ui_exp', searchParams.get('lum_last_user_loggedin') as string);
      setIsLoggedIn(true);
      router.push('plans');
    } else {
      const expDate = localStorage.getItem('_lum_ui_exp');
      if (expDate && new Date(expDate) > new Date()) {
        setIsLoggedIn(true);
      } else setIsLoggedIn(false);
    }
  }, [router, searchParams]);

  useEffect(() => {
    if (width > 768 && scroll.y > 150 && scroll.y - scroll.lastY > 0) setShouldNavBeHidden(true);
    else setShouldNavBeHidden(false);
  }, [scroll.y, scroll.lastY, width]);

  const handleDecideResponsiveMenu = () => {
    if (width >= 767.98 && width <= 992) setShowTabletMenu(true);
    else if (width >= 576 && width < 767.98) setShowOffCanvas(true);
    else setShowOffCanvas(true);
  };

  const handleAnimateTitle = () => {
    document.getElementById('lm-txt')?.classList.add('d-none');
    document.getElementById('lm-dash')?.classList.remove('d-none');
    document.getElementById('lm-domain-txt')?.classList.remove('d-none');
  };

  const handleRevertTitle = () => {
    document.getElementById('lm-txt')?.classList.remove('d-none');
    document.getElementById('lm-dash')?.classList.add('d-none');
    document.getElementById('lm-domain-txt')?.classList.add('d-none');
  };

  const handleScrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Navbar variant="light" sticky="top" className={`lk-primary-navbar ${shouldNavBeHidden ? 'nav-hidden' : ''}`}>
        <Container fluid={'xl'}>
          <Row className="w-100 align-items-center g-0">
            <Col lg={4} md={11} xs={11}>
              <Link
                href={`/home/${lang}`}
                className="navbar-brand d-block"
                onMouseEnter={handleAnimateTitle}
                onMouseLeave={handleRevertTitle}
                onClick={handleScrollTop}
              >
                <Image
                  alt="L-K.io"
                  title="l-k.io logo"
                  src="https://cdn.1cdn.app/application/LUMBERJACK/2208240622_logo.svg"
                  width="40"
                  height="40"
                  className="d-inline-block align-center"
                />{' '}
                <span className="ms-2 lk-transition">
                  <b className="lk-text-red">L</b>
                  <span id="lm-txt">umberJac</span>
                  <b id="lm-dash" className="fw-light d-none">
                    -
                  </b>
                  <b className="lk-text-red">k</b>
                  <span id="lm-domain-txt" className="d-none">
                    .io
                  </span>
                </span>
              </Link>
            </Col>
            <Col className="d-none d-lg-flex">
              <Nav className="mx-auto lk-navbar-nav align-items-center justify-content-center">
                <Link href={`/home/${lang}/plans`} className="me-3 nav-link">
                  {t('LKNavbar/key5')}
                </Link>
                <Link href={`/home/${lang}/demo`} className="me-3 nav-link">
                  {t('LKNavbar/key2')}
                </Link>
                <Link href={`/home/${lang}/news`} className="nav-link">
                  {t('LKNavbar/key4')}
                </Link>
              </Nav>
            </Col>
            <Col lg={4} md={1} xs={1}>
              {showTabletMenu ? (
                <BsX
                  className="lk-text-red fs-1 d-lg-none d-block lk-cursor-pointer"
                  onClick={() => setShowTabletMenu(false)}
                  aria-label="Close menu"
                  aria-hidden
                />
              ) : (
                <BsList
                  className="lk-text-red fs-1 d-lg-none d-block lk-cursor-pointer"
                  aria-controls="responsive-navbar-nav"
                  onClick={handleDecideResponsiveMenu}
                  aria-label="Open menu"
                  aria-hidden
                />
              )}
              <Nav className="lk-navbar-nav-auth align-items-center justify-content-lg-end d-none d-lg-flex">
                {isLoggedIn ? (
                  <Nav.Link
                    href={process.env.NEXT_PUBLIC_DASHBOARD_DOMAIN + '/control-panel'}
                    className="lk-red-button text-white nav-link"
                  >
                    {t('LKNavbar/key1')}
                  </Nav.Link>
                ) : (
                  <>
                    <Nav.Link
                      href={process.env.NEXT_PUBLIC_DASHBOARD_DOMAIN + `/login?language=${lang}`}
                      className="me-3"
                    >
                      {t('LKNavbar/key3')}
                    </Nav.Link>
                    <Nav.Link
                      href={process.env.NEXT_PUBLIC_DASHBOARD_DOMAIN + `/register?language=${lang}`}
                      className="me-3 lk-red-button text-white"
                    >
                      {t('LKNavbar/key6')}
                    </Nav.Link>
                  </>
                )}
                <I18nLocaleChanger className={'ms-3'} />
              </Nav>
            </Col>
          </Row>
        </Container>
        <Offcanvas
          show={showOffCanvas}
          onHide={() => setShowOffCanvas(false)}
          placement={'end'}
          backdrop={false}
          scroll={true}
        >
          <Offcanvas.Header closeButton />
          <Offcanvas.Body className="d-flex flex-column">
            <h4 className="mb-4">Lumberjack</h4>
            <Nav className="lk-navbar-nav fw-light lk-text-black">
              <Link
                onClick={() => setShowOffCanvas(false)}
                href={`/home/${lang}/plans`}
                className="nav-link py-3 border-bottom"
              >
                {t('LKNavbar/key5')}
              </Link>
              <Link
                onClick={() => setShowOffCanvas(false)}
                href={`/home/${lang}/demo`}
                className="nav-link py-3 border-bottom"
              >
                {t('LKNavbar/key2')}
              </Link>
              <Link
                onClick={() => setShowOffCanvas(false)}
                href={`/home/${lang}/news`}
                className="nav-link py-3 border-bottom"
              >
                {t('LKNavbar/key4')}
              </Link>
            </Nav>
            <I18nLocaleChanger className={'mt-3'} />
            <Nav className="lk-navbar-nav-auth fw-light mt-auto">
              <Nav.Link
                href={process.env.NEXT_PUBLIC_DASHBOARD_DOMAIN + `/login?language=${lang}`}
                className="mb-3 btn w-100 bg-white lk-text-red rounded-pill shadow-sm"
              >
                {t('LKNavbar/key3')}
              </Nav.Link>
              <Nav.Link
                href={process.env.NEXT_PUBLIC_DASHBOARD_DOMAIN + `/register?language=${lang}`}
                className="btn lk-bg-red rounded-pill w-100 text-white"
              >
                {t('LKNavbar/key6')}
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
      </Navbar>
      {showTabletMenu && (
        <div className="lk-tablet-menu">
          <div className="d-flex flex-column">
            <h4 className="mb-4">Lumberjack</h4>
            <Nav className="lk-navbar-nav fw-light lk-text-black flex-column">
              <Link
                onClick={() => setShowTabletMenu(false)}
                href={`/home/${lang}/plans`}
                className="nav-link py-3 border-bottom"
              >
                {t('LKNavbar/key5')}
              </Link>
              <Link
                onClick={() => setShowTabletMenu(false)}
                href={`/home/${lang}/demo`}
                className="nav-link py-3 border-bottom"
              >
                {t('LKNavbar/key2')}
              </Link>
              <Link
                onClick={() => setShowTabletMenu(false)}
                href={`/home/${lang}/news`}
                className="nav-link py-3 border-bottom"
              >
                {t('LKNavbar/key4')}
              </Link>
            </Nav>
            <I18nLocaleChanger className={'mt-3'} />
            <Nav className="lk-navbar-nav-auth fw-light mt-auto">
              <Nav.Link
                href={process.env.NEXT_PUBLIC_DASHBOARD_DOMAIN + `/login?language=${lang}`}
                className="mb-3 btn w-100 bg-white rounded-pill lk-text-black shadow-sm"
              >
                {t('LKNavbar/key3')}
              </Nav.Link>
              <Nav.Link
                href={process.env.NEXT_PUBLIC_DASHBOARD_DOMAIN + `/register?language=${lang}`}
                className="btn lk-bg-red rounded-pill w-100 text-white"
              >
                {t('LKNavbar/key6')}
              </Nav.Link>
            </Nav>
          </div>
        </div>
      )}
    </>
  );
};

export default LKNavbar;
