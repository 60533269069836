'use client';

import { useEffect } from 'react';
import { setCookie } from 'cookies-next';
import { useParams } from 'next/navigation';
import { useTranslation } from '@/i18n/client';
import { Locale } from '../../i18n-config';

const LanguageInitiator = ({ children }) => {
  const params = useParams();
  const { i18n } = useTranslation(params?.lang as Locale);

  useEffect(() => {
    i18n.on('languageChanged', (lng) => {
      setCookie('NEXT_LOCALE', lng);
    });
  }, [i18n]);

  return children;
};

export default LanguageInitiator;
