'use client';

import { useParams } from 'next/navigation';
import React from 'react';
import LKOffers from './LKOffers';
import LKNavbar from './LKNavbar';
import LKFooter from './LKFooter';

const WithLayout = ({ children }: { children: React.ReactNode }) => {
  const params = useParams();

  if (params && params.shortId) return <React.Fragment>{children}</React.Fragment>;

  return (
    <React.Fragment>
      <LKOffers lang={params?.lang} />
      <LKNavbar lang={params?.lang} />
      {children}
      <LKFooter lang={params?.lang} />
    </React.Fragment>
  );
};

export default WithLayout;
