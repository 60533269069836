import { setCookie } from 'cookies-next';
import { ReadonlyURLSearchParams } from 'next/navigation';

function slugify(str: string) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim leading/trailing white space
  str = str.toLowerCase(); // convert string to lowercase
  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
  return str;
}

const enumPlansTechnicalTerms = {
  LUMBERJACK_FREE_ANNUAL: 'Annually free plan',
  LUMBERJACK_FREE_MONTH: 'Monthly free plan',
  LUMBERJACK_FREE_TRIAL: 'Free trial',
  LUMBERJACK_PREMIUM_BUSINESS_ANNUAL: 'Annually business plan',
  LUMBERJACK_PREMIUM_PREMIUM_ANNUAL: 'Annually premium plan',
  LUMBERJACK_PREMIUM_PREMIUM_MONTH: 'Monthly premium plan',
  LUMBERJACK_PREMIUM_BUSINESS_MONTH: 'Monthly business plan',
  LUMBERJACK_PREMIUM_ONE_TIME_CHARGE: 'One time charge',
  LUMBERJACK_PREMIUM_ENTERPRISE_MONTH: 'Monthly enterprise plan',
  LUMBERJACK_PREMIUM_ENTERPRISE_ANNUAL: 'Annually enterprise plan',
  LUMBERJACK_PREMIUM_PREMIUM_ANNUAL_WITH_TRIAL: 'Annually premium plan with trial',
  LUMBERJACK_PREMIUM_ENTERPRISE_ANNUAL_WITH_TRIAL: 'Annually enterprise plan with trial',
  LUMBERJACK_PREMIUM_BUSINESS_ANNUAL_WITH_TRIAL: 'Annually business plan with trial',
  LPBOTC_876KHFD67DF: 'Basic one time charge',
  LPBOM_TH9EGTNJ8I: 'Monthly business plan',
  LPBOA_T3LEJ0KSDA: 'Annually business plan',
  LPBOA_BA7C0BO69A: 'Annually business plan with trial',
  LPBOM_2D1AXKCTGD: 'Monthly premium plan',
  LPBOA_NKGD5D6SO0: 'Annually premium plan',
  LPBOA_J0BDEYGT7L: 'Annually premium plan with trial',
  LPBOM_OGL6KT18EX: 'Monthly enterprise plan',
  LPBOA_EX8YK79D4B: 'Annually enterprise plan',
  LPBOM_1NUPLESRUM: 'Annually enterprise plan with trial',
} as any;

const utmManager = (searchParams: ReadonlyURLSearchParams): void => {
  const utm_datas = {
    gad_source: searchParams.has('gad_source') ? searchParams.get('gad_source') : '',
    gclid: searchParams.has('gclid') ? searchParams.get('gclid') : '',
    utm_source: searchParams.has('utm_source') ? searchParams.get('utm_source') : '',
    utm_medium: searchParams.has('utm_medium') ? searchParams.get('utm_medium') : '',
    utm_campaign: searchParams.has('utm_campaign') ? searchParams.get('utm_campaign') : '',
    utm_content: searchParams.has('utm_content') ? searchParams.get('utm_content') : '',
    utm_term: searchParams.has('utm_term') ? searchParams.get('utm_term') : '',
    fbclid: searchParams.has('fbclid') ? searchParams.get('fbclid') : '',
  };

  if (
    window.navigator.cookieEnabled &&
    typeof sessionStorage !== 'undefined' &&
    typeof localStorage !== 'undefined' &&
    localStorage.getItem('consentMode') &&
    JSON.parse(localStorage.getItem('consentMode') as string).analytics_storage &&
    JSON.parse(localStorage.getItem('consentMode') as string)?.analytics_storage === 'granted'
  ) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const newYear = currentYear + 1;
    currentDate.setFullYear(newYear);

    for (const key in utm_datas) {
      if (utm_datas[key] === '') {
        delete utm_datas[key];
      }
    }

    if (Object.keys(utm_datas).length > 0)
      setCookie('utm_datas', JSON.stringify(utm_datas), {
        expires: currentDate,
        domain:
          location.hostname.split('.').length > 2
            ? '.' +
              location.hostname
                .split('.')
                .slice(location.hostname.split('.').length - 2, location.hostname.split('.').length)
                .join('.')
            : '.' + location.hostname,
        secure: true,
        sameSite: 'none',
      });
  }
};

const Utils = {
  enumPlansTechnicalTerms,
  slugify,
  utmManager,
};

export default Utils;
