import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/swiper.min.css");
import(/* webpackMode: "eager" */ "/app/node_modules/aos/dist/aos.css");
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/modules/navigation/navigation.min.css");
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/modules/pagination/pagination.min.css");
import(/* webpackMode: "eager" */ "/app/node_modules/bootstrap/dist/css/bootstrap.min.css");
import(/* webpackMode: "eager" */ "/app/src/components/common/consentManager/ConsentManager.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/common/utmManager/UTM.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/LanguageInitiator.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/WithLayout.tsx");
import(/* webpackMode: "eager" */ "/app/src/index.css")